import axios from "axios";
import route from "@/router/index.js";

import { showLoading, hideLoading } from "@/public/loading";
const developURL = "http://192.168.31.132:8090/rselectool/v4";
const onLineURL = "https://api.ibp.ibeisai.com";
const requests = axios.create({
  baseURL: onLineURL,

  headers: {
    "content-Type": "application/json",
  },
});

// 请求拦截
requests.interceptors.request.use(
  (config) => {
    if (config.url != "/project/vcr" || config.url.indexOf("/aigc") != -1) {
      showLoading();
    }
    if (sessionStorage.getItem("token")) {
      config.headers["token"] = sessionStorage.getItem("token");
    }

    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截
requests.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      hideLoading();

      if (res.data.code == -4) {
        route.push({
          path: "/login",
        });
      }
      return Promise.resolve(res);
    }
  },
  (error) => {}
);

export const useAxios = () => {
  const get = (url, data) => {
    return requests.get(url, { params: { data } });
  };
  const post = (url, data) => {
    return requests.post(url, data);
  };
  const upload = (url, data) => {
    return axios.post(url, data);
  };
  const del = (url, data) => {
    return requests.delete(url, data);
  };
  return { get, post, upload, del };
};

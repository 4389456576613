<template>
  <router-view />
</template>
<script setup>

</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei-Regular";
  user-select: none;
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

//按钮区域
.mini-myBtn {
  width: 68px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #40c6ff;
  color: #40c6ff;
  margin-left: 16px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;

  &:hover {
    background: darken(#fff,2%);
  }

  &:active {
   background: darken(#fff,5%);
  }
}

.mini-myBtn-main {
  background: #40c6ff;
  color: #fff;

  &:hover {
    border: 1px solid transparent;
    background: lighten(#40c6ff,10%);
  }

  &:active {
    border: 1px solid transparent;
   background: darken(#40c6ff,10%);
  }
}

.myBtn {
  padding: 0 16px;
  height: 32px;
  color: #40c6ff;
  border: 1px solid #40c6ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: darken(#fff,2%);
  }

  &:active {
   background: darken(#fff,5%);
  }

  i {
    margin-right: 4px;
  }
}

.myBtn-main {
  background: #40c6ff;
  color: #fff;

  &:hover {
    border: 1px solid transparent;
    background: lighten(#40c6ff,10%);
  }

  &:active {
    border: 1px solid transparent;
   background: darken(#40c6ff,10%);
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield !important; /* Firefox */
}


.highlighted {
  // background-color: yellow !important; /* 或者你喜欢的其他颜色 */
  background: rgb(182 233 255 / 39%) !important;
}

h3 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 12px;
      background: #40c6ff;
      border-radius: 0px 0px 0px 0px;
      top: 50%;
      left: -20px;
      transform: translate(0, -50%);
    }
  }

</style>

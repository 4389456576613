// 首页Home
// 引入defineStore
import { defineStore } from "pinia";

// 导出
export const UserData = defineStore("UserData", {
  state() {
    return {
      userimg: "",
      username: "",
      userphone: "",
      usertype: "",
      setUser: false,
      userCode:''
    };
  },
  actions: {},
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createPinia } from "pinia";
import "@/assets/font/iconfont.css";
const storePinia = createPinia();

createApp(App)
  .use(store)
  .use(ElementPlus)
  .use(storePinia)
  .use(router)
  .mount("#app");

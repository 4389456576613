import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { ElMessageBox } from "element-plus";
import { ShadowData } from "@/pinia/shadow.js";
import { watch } from "vue";

const routes = [
  { path: "/", redirect: "/sthome" },
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/sthome",
        name: "sthome",
        component: () => import("@/views/student/StHome.vue"),
      },
      {
        path: "/stmyobj",
        name: "stmyobj",
        component: () => import("@/views/student/StMyObj.vue"),
      },
      {
        path: "/stobjinfo",
        name: "stobjinfo",
        component: () => import("@/views/student/StObjInfo.vue"),
      },
      {
        path: "/coverfrom",
        name: "coverfrom",
        component: () => import("@/views/student/CoverFrom.vue"),
      },
      {
        path: "/pptview",
        name: "pptview",
        component: () => import("@/views/student/PptView.vue"),
      },
      {
        path: "/pptview2",
        name: "pptview2",
        component: () => import("@/views/student/PptView2.vue"),
      },
      {
        path: "/thhome",
        name: "thhome",
        component: () => import("@/views/teacher/ThHome.vue"),
      },
      {
        path: "/intocourse",
        name: "intocourse",
        component: () => import("@/views/teacher/IntoCourse.vue"),
      },
      // 个人中心
      {
        path: "/self",
        name: "self",
        component: () => import("@/views/self/SelfInfo.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/LoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const shadowStore = ShadowData();
  if (to.path == "/login") {
    next();
  } else {
    const token = sessionStorage.getItem("token"); // 获取token
    // token不存在
    if (token === null || token === "") {
      next("/login");
    } else {
      if (from.path == "/coverfrom") {
        if (sessionStorage.getItem("ifSubmitData") == "false") {
          ElMessageBox.confirm("是否保存项目内容并退出", "提示", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            center: true,
            cancelButtonClass: "cancelBtn",
          })
            .then(() => {
              next();
            })
            .catch(() => {});
        } else {
          sessionStorage.setItem("ifSubmitData", "false");

          next();
        }
      } else {
        next();
      }
    }
  }
});

export default router;

<template>
  <div class="allObjBox">
    <nav>
      <div class="logo">
        <!-- IBP商业计划书 -->
        <h1>
          <img  src="../assets/st.png" alt="" />
          <!-- <img v-else src="../assets/th.png" alt="" /> -->
        </h1>
      </div>

      <div class="right-area">
        <div class="returnBox">
          <div class="href"></div>
        </div>

        <div class="userImgName">
          <div class="img">
            <img
              v-if="userStory.userimg == null"
              src="../assets/stImg/defaultUserImg.png"
              alt=""
            />
            <img v-else :src="userStory.userimg" alt="" />
          </div>
          <p>{{ userStory.username }}</p>

          <div class="sub-menu">
            <ul>
              <li @click="intoSelf">个人中心</li>
              <li @click="logout">退出登录</li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="button">
     

      <div class="right-area">
        <!-- <el-scrollbar> -->
          <div class="scrollbox">
            <router-view />
          </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>
  </div>

  <transition name="run">
    <div class="shadow" v-if="shadowStore.ShadowShow" @touchmove.prevent></div>
  </transition>
</template>

<script setup>
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { ShadowData } from "@/pinia/shadow.js";
import { reactive, watch, onMounted } from "vue";
import { useAxios } from "@/units/index.js";
import { UserData } from "@/pinia/user.js";
import { ElMessage } from "element-plus";


const userStory = UserData();
const https = useAxios();
const shadowStore = ShadowData();
const router = useRouter();

let state = reactive({
  routerData: [],
  // 人员类型 1 => 学生
  ut: sessionStorage.getItem("ut"),

  cstext1: "",
  time: null,
});

const intoMyCourse = () => {
  state.nowAtThisPage = 1;
  if (state.ut == 1) {
    router.push("/sthome");
  } else {
    router.push("/thhome");
  }
};

// 个人中心
const intoSelf = () => {
  router.push("/self");
};

onMounted(() => {
  // 存入一个sessionStorage 去进行数据提交的判断
  sessionStorage.setItem("ifSubmitData", false);

  getUserInfo();
  // 将路径存储在sessionStorage中,防止用户点击刷新将路由信息丢失

  if (!sessionStorage.getItem("sessionRouter")) {
    if (state.ut == 1) {
      state.routerData.push({
        path: "/sthome",
        label: "我的课堂",
      });
    } else if (state.ut == 3) {
      state.routerData.push({
        path: "/thhome",
        label: "我的课堂",
      });
    }

    sessionStorage.setItem("sessionRouter", JSON.stringify(state.routerData));
  } else {
    state.routerData = [...JSON.parse(sessionStorage.getItem("sessionRouter"))];
  }
});

// 监听用户是否修改了信息，如果修改了则重新调用getUserInfo

watch(
  () => userStory.setUser,
  (newVal, oldVal) => {
    if (newVal) {
      getUserInfo();
      userStory.setUser = false;
    }
  }
);

watch(
  () => shadowStore.ShadowShow,
  (newval, oldval) => {
    if (newval) {
      stopMove();
    } else {
      Move();
    }
  }
);

watch(
  () => shadowStore.bandPhone,
  (newval, oldval) => {
    if (!newval) {
      getUserInfo();
    }
  }
);

const getUserInfo = () => {
  https.post("/user/info").then(({ data }) => {
    if (data.success) {
      userStory.userimg = data.data.user_avatar;
      userStory.username = data.data.user_name;
      userStory.userphone = data.data.user_mobile;
      userStory.usertype = data.data.user_type;

      userStory.userCode = data.data.user_code;
    } else {
      ElMessage.error(data.message);
    }
  });
};

// 退出登录
const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  router.push("/login");
};



// 用户跳转路径记录
onBeforeRouteUpdate((to) => {
  // window.scrollTo(0,0);
  let query = "";
  if (to.query.type != "") {
    query = to.query.type;
  }

  setRoutedata(to.path, query);
});

const setRoutedata = (path, query) => {
  let ro = {
    path: "",
    label: "",
  };

  ro.path = path;

  if (path == "/sthome") {
    state.routerData = [];

    ro.label = "我的课堂";
  } else if (path == "/stmyobj") {
    ro.label = "我的项目";
  } else if (path == "/stobjinfo") {
    ro.label = "项目内容";
  } else if (path == "/coverfrom") {
    if (query == 1) {
      ro.label = "封面";
    } else if (query == 2) {
      ro.label = "项目介绍";
    } else if (query == 3) {
      ro.label = "市场分析";
    } else if (query == 4) {
      ro.label = "产品介绍";
    } else if (query == 5) {
      ro.label = "商业模式";
    } else if (query == 6) {
      ro.label = "项目资源";
    } else if (query == 7) {
      ro.label = "团队介绍";
    } else if (query == 8) {
      ro.label = "发展战略";
    } else if (query == 9) {
      ro.label = "融资计划";
    } else if (query == 10) {
      ro.label = "财务分析";
    } else if (query == 11) {
      ro.label = "风险因素";
    } else if (query == 12) {
      ro.label = "教育维度";
    }
  } else if (path == "/self") {
    state.routerData = [];
    if (state.ut == 1) {
      state.routerData.push({
        path: "/sthome",
        label: "我的课堂",
      });
    } else if (state.ut == 3) {
      state.routerData.push({
        path: "/thhome",
        label: "我的课堂",
      });
    }

    ro.label = "个人中心";
  } else if (path == "/thhome") {
    state.routerData = [];

    ro.label = "我的课堂";
  } else if (path == "/intocourse") {
    ro.label = "课堂详情";
  } else if (path == "/pptview") {
    ro.label = "PPT编辑";
  }else if (path == "/pptview2") {
    ro.label = "PPT编辑";
  }

  state.routerData.forEach((item, index) => {
    if (item.path == path) {
      state.routerData.splice(index, state.routerData.length - index + 1);
    }
  });

  state.routerData.push(ro);
  sessionStorage.setItem("sessionRouter", JSON.stringify(state.routerData));
};

//停止页面滚动
const stopMove = () => {
  let m = function (e) {
    e.preventDefault();
  };
  document.body.style.overflow = "hidden";
  document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
};
//开启页面滚动
const Move = () => {
  let m = function (e) {
    e.preventDefault();
  };
  document.body.style.overflow = "";
  //出现滚动条
  document.removeEventListener("touchmove", m, { passive: true });
};
</script>

<style lang="scss" scoped>
.allObjBox {
  width: 100%;
  margin-top: 80px;
  // overflow: hidden;
}
nav {
  width: 100%;
  height: 80px;
  background: #40c6ff;
  display: flex;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
  padding-right: 40px;
  position: relative;
  z-index: 2;
  position: fixed;
  top: 0;


  .logo {
    width: 240px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;

    h1 {
      width: 100%;
      height: 100%;
     
      padding: 4px 0;
      

      img {
        height: 100%;
      }
    }
  }

  .right-area {
    width: calc(100%);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .returnBox {
      margin-left: 24px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }

    .href {
      margin-left: 8px;
    }

    .userImgName {
      height: 80px;
      display: flex;
      text-align: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        .sub-menu {
          display: block;
        }
      }

      .img {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        line-height: 0px;
        -webkit-background-clip: text;
        margin-left: 8px;
      }

      .sub-menu {
        width: 150px;
        height: 120px;
        background: #fff;
        position: absolute;
        top: 80px;
        left: -50px;
        z-index: 2;
        padding: 10px;
        display: none;
        border-radius: 6px;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          li {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            font-size: 18px;

            &:hover {
              background: #f5f5f5;
            }
          }
        }
      }
    }
  }
}

.button {
  width: 100%;
  height: 100%;
  display: flex;
  background: #f5f5f5;



  .right-area {
    width: calc(100%);
    // min-height: calc(1080px - 100px);
    // overflow: hidden;
    .scrollbox {
      width: calc(100%);
      padding: 20px 40px;
    }
  }
}

.shadow {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 2s;
}

// 淡出淡入
// 元素开始进入的状态 | 元素离开结束的状态
.run-enter-from,
.run-leave-to {
  opacity: 0;
}
// 元素进入结束的状态 ｜ 元素开始离开的状态。     这里不写也可以！！！！！！
.run-enter-to,
.run-leave-from {
  opacity: 1;
}
// 元素进入 ｜ 结束时，过渡的效果
.run-enter-active,
.run-leave-active {
  // 过渡动画的使用
  transition: opacity 0.2s linear 0s;
}

.cancelBtn {
  padding: 0 20px !important;
  height: 32px !important;
  color: #40c6ff !important;
  border: 1px solid #40c6ff !important;
}

:deep .el-message-box__header {
  background: red !important;
}

:deep .is-link {
  font-size: 16px !important;
}
:deep .el-breadcrumb__separator {
  font-size: 16px !important;
}
</style>


// 首页Home
// 引入defineStore
import { defineStore } from "pinia";

// 导出
export const ShadowData = defineStore("ShadowData", {
  state() {
    return {
      // 学生
      ShadowShow: false, // 背景灰色
      addCourse: false, // 加入课程
      selectGroup: false, //选择小组
      cutGroup: false, //切换小组
      newObj: false, // 新建项目
      getTrackData: false, // 新建项目时到的赛道数据
      Tips: false, // 提示部分
      TipsType: "",
      TipsText: "",
      apportionRole: false, // 分配角色
      apportionCom: false, // 分配模块
      preview: false, // 预览
      note: false, // 批注
      caselist: false, // 模版案例
      pptshow: false,
      covercom: false,
      vcrpanel: false, // VCR面板
      // 老师
      createCourse: false, // 创建课堂
      setPwd: false, // 修改密码
      setName: false, // 修改昵称
      setLeaderman: false, // 修改成员
      exitTips: false,
      exitNext: false, // 退出
      previewText: false, // 用于点击预览时，刷新预览的数据
      teamScore: false, // 评分
      studentScore: false, // 成员分数
      setLeader: false, // 修改组长
      bandPhone: false, // 绑定手机号
      defaultPPT: false, // 设置默认ppt
    };
  },
  actions: {
    SETSHADOWSHOW() {
      this.ShadowShow = !this.ShadowShow;
    },
    SETADDCOURSE() {
      this.addCourse = !this.addCourse;
    },
    SETSELECTGROUP() {
      this.selectGroup = !this.selectGroup;
    },
    SETCUTGROUP() {
      this.cutGroup = !this.cutGroup;
    },
    SETNEWOBJ() {
      this.newObj = !this.newObj;
    },
    SETGETTRACKDATA() {
      this.getTrackData = !this.getTrackData;
    },
    SETTIPS(type, text) {
      this.Tips = !this.Tips;
      this.TipsType = type;
      this.TipsText = text;
    },
    SETAPPORTIONROLR() {
      this.apportionRole = !this.apportionRole;
    },
    SETAPPORTIONCOM() {
      this.apportionCom = !this.apportionCom;
    },
    SETPREVIEW() {
      this.preview = !this.preview;
    },
    SETNOTE() {
      this.note = !this.note;
    },
    SETCASELIST() {
      this.caselist = !this.caselist;
    },
    SETCREATECOURSE() {
      this.createCourse = !this.createCourse;
    },
    SETSETPWD() {
      this.setPwd = !this.setPwd;
    },
    SETSETNAME() {
      this.setName = !this.setName;
    },
    SETSETLEADERMAN() {
      this.setLeaderman = !this.setLeaderman;
    },
    SETEXITTIPS() {
      this.exitTips = !this.exitTips;
    },
    SETEXTINEXT(booler) {
      this.exitNext = booler;
    },
    SETPREVIEWTEXT() {
      this.previewText = !this.previewText;
    },
    SETTEAMSCORE() {
      this.teamScore = !this.teamScore;
    },
    SETSTUDENTSCORE() {
      this.studentScore = !this.studentScore;
    },

    SETSETLEADER() {
      this.setLeader = !this.setLeader;
    },

    SETBANDPHONE() {
      this.bandPhone = !this.bandPhone;
    },
    SETPPTSHOW() {
      this.pptshow = !this.pptshow;
    },
    SETCOVERCOM() {
      this.covercom = !this.covercom;
    },
    SETVCRPANEL() {
      this.vcrpanel = !this.vcrpanel;
    },
    SETDEFAULTPPT() {
      this.defaultPPT = !this.defaultPPT;
    },
  },
});
